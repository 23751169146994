import { Component, Vue, Watch } from 'vue-property-decorator';
import * as API_activityType from '@/api/activityType';
// import MdEditor from '@/components-new/md-editor/md-editor.vue';
import ImgUploader from "@/components-new/img-uploader/img-uploader.vue";
import SigEditorLite from "@/components-new/sig-editor-lite/sig-editor-lite.vue";
import {
  addNews,
  editNews,
  getNews
} from "@/api/$platform";

export default @Component({
  name: 'activity-information-detail',
  components: {
    // MdEditor,
    ImgUploader,
    SigEditorLite
  }
})
class ActivityZoneDetailPage extends Vue {
  imagestitle='封面图(1张, 必传)'
  ruleForm = {
    title: "",
    type: 0,
    image_type: '',
    image: '',
    video: '',
    content: '',
    category_id: 7,
  }
  videoList=[]
  temp = {}
  filterImg(rule, value, callback) {
    if (this.ruleForm.image_type === 2 && value.length < 3) {
      callback(new Error("请上传三张图片"));
    } else if (this.ruleForm.image_type !== 2 && value.length > 1) {
      callback(new Error("最多上传一张图片"));
    }
    callback();
  }
  rules={
    title: { required: true, message: "请输入名称！", trigger: "blur" },
    image_type: { required: true, message: "请选择图片类型！", trigger: "change" },
    image: [
      { required: true, message: "请上传图片！", trigger: "change" },
      {
        required: true,
        validator: this.filterImg,
        trigger: "change",
      },
    ],
    video: { required: true, message: "请上传视频！", trigger: "change" },
    content: { required: true, message: "请输入内容！", trigger: "blur" },
  }
  dataset = { page_no: 1 };
  selectedData = [];
  searchContent = '';
  dialogParams={
    type: '',
    status: ''
  };
  selectedLists = [];
  parentId ='';
  get hasSelected() {
    return this.selectedData.length > 0;
  }
  async created () {
    if (this.$route.params.id) {
      this.parentId = this.$route.params.id;
      this.ruleForm.id = this.$route.params.id;
      const resdata = await getNews(this.$route.params.id)
      if (resdata.video) {
        const videoNames = resdata.video.split('/')
        this.videoList = [{url: resdata.video, name: videoNames[videoNames.length - 1]}]
      }
      if (resdata.image) {
        const lmageList = resdata.image.split(',');
        this.ruleForm = {
          ...resdata,
          image: lmageList.map(item => {
            return {
              url: item
            }
          })
        };
      } else {
        this.ruleForm = {
          ...resdata,
          image: []
        };
      }
      console.log(this.ruleForm);
      this.$refs.qwer.$refs.editor.append(this.ruleForm.content)
      this.imagestitle = this.ruleForm.image_type === 2 ? '封面图(3张, 必传)' : '封面图(1张, 必传)'
    }
  }
  beforeUpload (file) {
    const isImage = file.type === 'video/mp4' || file.type === 'video/rmvb' || file.type === 'video/avi'
    const isLt1M = file.size / 1024 / 1024 < 50
    if (!isImage) {
      this.$message.error('上传菜单图片只能是 JPG、JPEG或PNG格式!')
      return isImage
    }
    if (!isLt1M) {
      this.$message.error('上传视频大小不能超过50MB!')
      return isLt1M
    }
    return isImage;
  }
  // 上传成功回调
  handleUploadSuccess (response) {
    this.ruleForm.video = response.url;
  }
  changeImageType () {
    if (this.ruleForm.image_type === 2) {
      this.ruleForm.image = []
      this.imagestitle = '封面图(3张, 必传)'
    } else {
      this.imagestitle = this.ruleForm.image_type === 3 ? '' : '封面图(1张, 必传)'
    }
  }

  // 删除文件
  handleUploadRemove () {
    this.ruleForm.video = '';
    this.$refs.uploadVideo.clearFiles();
  }

  addEdit () {
    console.log(this.temp = JSON.parse(JSON.stringify(this.ruleForm)))
    this.$refs["ruleForm"].validate((valid) => {
      if (valid) {
        const params = {
          ...this.ruleForm,
        };
        if (this.ruleForm.image_type !== 3) {
          params.image = params.image.map(item => item.url).join(',')
        } else {
          params.image = ''
        }
        if (this.$route.params.id) {
          editNews(params.consult_id, params).then((res) => {
            this.$message.success("保存成功！");
            this.$router.go(-1)
          });
        } else {
          addNews(params).then((res) => {
            this.$message.success("保存成功！");
            this.$router.go(-1)
          });
        }
      }
    });
  }
}
