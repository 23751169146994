<template>
  <div class="page-activity-zone-detail">
    <!-- 资讯专区 -->
    <el-card>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="资讯标题:" prop="title" style="margin-bottom: 20px">
          <el-input style="width: 600px;" v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="资讯类型:" prop="type" style="margin-bottom: 20px">
          <el-select v-model="ruleForm.type" placeholder="请选择" clearable>
            <el-option label="图文" :value="0"></el-option>
            <el-option label="视频" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.type === 0" label="封面类型:" prop="image_type" style="margin-bottom: 20px">
          <el-select v-model="ruleForm.image_type" placeholder="请选择" clearable @change="changeImageType">
            <el-option label="左侧单图模式" :value="0"></el-option>
            <el-option label="大单图模式" :value="1"></el-option>
            <el-option label="三图模式" :value="2"></el-option>
            <el-option label="无图模式" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面图：" v-if="ruleForm.image_type !== 3"
          :prop="ruleForm.image_type === 3 ? 'unimage' : 'image'">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="upload-card">
                <img-uploader :limit="ruleForm.image_type === 2 ? 3 : 1" :file-list.sync="ruleForm.image"
                  :title="imagestitle"></img-uploader>
              </div>
            </div>
          </div>
          <div class="imgExplain">
            <span>* 请上传大小不超1MB，格式为jpg、png的图片</span>
          </div>
        </el-form-item>
        <!-- <el-form-item prop="background_img">
          <el-upload v-show="!ruleForm.image" accept=".jpeg, .png, .jpg" ref="uploadImage" style="display: flex"
            :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" :limit="1" :before-upload="beforeUpload"
            :on-success="handleUploadSuccess">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>
            提示：请上传大小不超1MB，格式为jpg、png的图片
          </div>
        </el-form-item> -->
        <el-form-item v-if="ruleForm.type === 1" label="视频上传:" prop="video" style="margin-bottom: 20px">
          <el-upload style="width: 500px;" ref="uploadVideo" accept=".mp4, .rmvb, .avi"
            :action="`${MixinUploadApi}?scene=goods`" :limit="1" :before-upload="beforeUpload"
            :on-remove="handleUploadRemove" :on-success="handleUploadSuccess" :file-list.sync="videoList">
            <el-button :disabled="ruleForm.video != ''" type="primary">添加视频</el-button>
            <template slot="tip">
              <div>
                提示：请上传大小不超50MB，格式为MP4、rmvb、avi的视频文件
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item v-if="ruleForm.type === 0" label="资讯内容:" prop="content" style="width: 60vw">
          <sig-editor-lite ref="qwer" v-model="ruleForm.content" :show-title="false"
            :show-priver="false"></sig-editor-lite>
          <!-- <md-editor v-model="ruleForm.content" @change="setContent"></md-editor> -->
        </el-form-item>
        <el-button type="primary" @click="addEdit" size="small">保存</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script lang="js">
import ActivityZoneDetailPage from './activity-information-detail';
export default ActivityZoneDetailPage;
</script>

<style lang="scss">
@import "./activity-information-detail";
</style>
